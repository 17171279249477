













import PageTitle from "piramis-base-components/src/components/PageTitle.vue"
import ServicesCart from "piramis-base-components/src/plugins/AdminTemplate/pages/services-cart.vue"

import Vue from 'vue'
import { Component } from "vue-property-decorator"
import moment from "moment"

@Component({
  components: {
    ServicesCart,
    PageTitle,
  },
})
export default class BuyBoard extends Vue {
  payMethods = this.defaultPayMethods()

  defaultPayMethods() {
    return {
      targetIdKey: 'board_key',
      config: {
        target_id: {
          board_key: this.$store.getters.activeBoard.board,
        },
        target_type: 'board'
      }
    }
  }

}
